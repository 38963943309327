import * as React from "react";
import { Link } from "gatsby";
import Container from "../../components/container";
import Layout from "../../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
// import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  description,
  row,
  // col6,
  col12,
  marginTopSubstitute,
  // hubSpotForm,
} from "../subpage.module.scss";

const TeilnahmBedienungenPage = () => {
  return (
    <Layout pageTitle="Teilnahmbedienungen Jahresend Gewinnspiel 2022" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <p className={description}>Jahresend Gewinnspiel 2022</p>
                <h1>Teilnahmbedienungen</h1>
                <p className="text-medium">
                Die Teilnahme am Gewinnspiel von the i-engineers AG nachfolgend 
                Betreiber oder Veranstalter genannt, ist kostenlos und richtet sich ausschließlich nach diesen Teilnahmebedingungen. </p>
            

            <div className={row}>
              <div className={col12}>
              <h3 className={marginTopSubstitute}>
                Ablauf des Gewinnspiels
                </h3>
                <p>
                Die Dauer des Gewinnspiels erstreckt sich vom 15. November 2022, 14:00 Uhr bis zum 12. Dezember 2022, 12:00 Uhr. Innerhalb dieses Zeitraums erhalten
                Nutzer online die Möglichkeit, am Gewinnspiel teilzunehmen.</p>
                
                <h3 className={marginTopSubstitute}>
                Teilnahme
                </h3>

                <p>Um am Gewinnspiel teilzunehmen, ist ein Ausfüllen und Absenden des angezeigten Teilnahmeformulars notwendig. Die Teilnahme ist nur innerhalb des 
                  Teilnahmezeitraums möglich. Nach Teilnahmeschluss eingehende Einsendungen werden bei der Auslosung nicht berücksichtigt.</p>

                <p>Pro Teilnehmer nimmt nur eine übermittelte Anmeldung am Gewinnspiel teil. Es ist strengstens untersagt, 
                  mehrere Email-Adressen zur Erhöhung der Gewinnchancen zu verwenden.</p>

                <p>Die Teilnahme am Gewinnspiel ist kostenlos.</p>

                <h3 className={marginTopSubstitute}>
                Teilnahmeberechtigte
                </h3>
                <p>Teilnahmeberechtigt sind natürliche Personen, die Ihren Wohnsitz in Deutschland / Schweiz und das 14. Lebensjahr vollendet haben. Die Teilnahme ist nicht auf Kunden
                   des Veranstalters beschränkt und nicht vom Erwerb einer Ware oder Dienstleistung abhängig.</p>
                <p>Sollte ein Teilnehmer in seiner Geschäftsfähigkeit eingeschränkt sein, bedarf es der Einwilligung seines gesetzlichen Vertreters.</p>
                <p>Nicht teilnahmeberechtigt am Gewinnspiel sind alle an der Konzeption und Umsetzung des Gewinnspiels beteiligte Personen und Mitarbeiter des Betreibers sowie ihre Familienmitglieder. 
                  Zudem behält sich der Betreiber vor, nach eigenem Ermessen Personen von der Teilnahme auszuschließen, wenn berechtigte Gründe vorliegen, beispielsweise</p>
              <p>(a) bei Manipulationen im Zusammenhang mit Zugang zum oder Durchführung des Gewinnspiels, (b) bei Verstößen gegen diese Teilnahmebedingungen, (c) bei unlauterem Handeln oder 
                (d) bei falschen oder irreführenden Angaben im Zusammenhang mit der Teilnahme an dem Gewinnspiel.</p>

                <h3 className={marginTopSubstitute}>
                Gewinn, Benachrichtigung und Übermittlung des Gewinns
                </h3>
                <p>Folgende Preise werden vergeben: Halben Tag Config. Gutschein (3x), max. einen Gutschein pro Unternehmen</p>
               
                <p>Die Ermittlung der Gewinner erfolgt nach Teilnahmeschluss im Rahmen einer auf dem Zufallsprinzip beruhenden Verlosung unter 
                  allen Teilnehmern. Ist das Gewinnspiel mit einer Aufgabe verknüpft, 
                  kommen ausschließlich derjenigen Teilnehmer in die Verlosung, welche die Aufgabe korrekt durchgeführt haben.</p>
                <p>Die Gewinner der Verlosung werden zeitnah über eine gesonderte E-Mail über den Gewinn informiert.</p>
                <p>Die Aushändigung des Gewinns erfolgt ausschließlich an den Gewinner oder an den gesetzlichen Vertreter des 
                  minderjährigen Gewinners. Ein Umtausch, eine Selbstabholung sowie eine Barauszahlung des Gewinns sind nicht möglich.</p>
              <p>Eventuell für den Versand der Gewinne anfallende Kosten übernimmt der Betreiber. Mit der Inanspruchnahme des Gewinns verbundene Zusatzkosten 
                gehen zu Lasten des Gewinners. Für eine etwaige Versteuerung des Gewinns ist der Gewinner selbst verantwortlich.</p>
                  <p>Meldet sich der Gewinner nach zweifacher Aufforderung innerhalb einer Frist von 3 Wochen nicht, kann der Gewinn auf einen 
                    anderen Teilnehmer übertragen werden.</p>


                    <h3 className={marginTopSubstitute}>
                    Beendigung des Gewinnspiels
                </h3>
                <p>Der Veranstalter behält sich ausdrücklich vor, das Gewinnspiel ohne vorherige Ankündigung und ohne Mitteilung von Gründen zu beenden. Dies gilt 
                  insbesondere für jegliche Gründe, die einen planmäßigen Ablauf des Gewinnspiels stören oder verhindern würden.</p>

                  <h3 className={marginTopSubstitute}>
                  Datenschutz
                </h3>
                <p>Für die Teilnahme am Gewinnspiel ist die Angabe von persönlichen Daten notwendig. Der Teilnehmer versichert, 
                  dass die von ihm gemachten Angaben zur Person, insbesondere Vor-, Nachname und Emailadresse wahrheitsgemäß und richtig sind. </p>
                <p>Der Veranstalter weist darauf hin, dass sämtliche personenbezogenen Daten des Teilnehmers ohne Einverständnis weder an Dritte 
                  weitergegeben noch diesen zur Nutzung überlassen werden.</p>
                <p>Eine Ausnahme stellt das für die Durchführung des Gewinnspiels beauftragte Unternehmen the i-engineers AG dar, welches die Daten 
                  zum Zwecke der Durchführung des Gewinnspiels erheben, speichern und nutzen muss.</p>
                <p>Im Falle eines Gewinns, erklärt sich der Gewinner mit der Veröffentlichung seines Namens und Wohnorts in den vom Veranstalter 
                  genutzten Werbemedien einverstanden. Dies schließt die Bekanntgabe des Gewinners auf der Webseite des Betreibers und seinen Social Media Plattformen mit ein.</p>
                <p>Der Teilnehmer kann seine erklärte Einwilligung jederzeit widerrufen. Der Widerruf ist schriftlich an die im Impressumsbereich auf unserer Webseite angegebenen Kontaktdaten des Veranstalters zu richten. 
                  Nach Widerruf der Einwilligung werden die erhobenen und gespeicherten personenbezogenen Daten des Teilnehmers umgehend gelöscht.</p>

                  <h3 className={marginTopSubstitute}>
                  Facebook Disclaimer
                </h3>
                <p>Diese Aktion steht in keiner Verbindung zu Facebook und wird in keiner Weise von Facebook gesponsert, 
                  unterstützt oder organisiert.</p>

                  <h3 className={marginTopSubstitute}>
                  Anwendbares Recht
                </h3>
                <p>Fragen oder Beanstandungen im Zusammenhang mit dem Gewinnspiel sind an den Betreiber zu richten. Kontaktmöglichkeiten
                   finden sich im Impressumsbereich oder im Footer auf unserer Webseite.</p>
                   <p>Das Gewinnspiel des Betreibers unterliegt ausschließlich dem Recht der Bundesrepublik Deutschland. 
                    Der Rechtsweg ist ausgeschlossen.</p>

                    <h3 className={marginTopSubstitute}>
                    Salvatorische Klausel
                </h3>
                  <p>Sollte eine Bestimmung dieser Teilnahmebedingungen ganz oder teilweise unwirksam sein oder werden, 
                    so wird dadurch die Gültigkeit dieser Teilnahmebedingungen im Übrigen nicht berührt. Statt der unwirksamen Bestimmung 
                    gilt diejenige gesetzlich zulässige Regelung, die dem in der unwirksamen Bestimmung zum Ausdruck gekommenen Sinn und Zweck
                     wirtschaftlich am nächsten kommt. Entsprechendes gilt für den Fall des Vorliegens einer Regelungslücke in diesen Teilnahmebedingungen.</p>
                    <p>Viel Glück und Erfolg wünscht das Team von the i-engineers</p>

                    <h3 className={marginTopSubstitute}>
                    <Link to="/landingpages/jahresend-verlosung/">Zurück zum Gewinnspiel {">"}</Link>
                </h3>

                 </div>
                 
                

            </div>
            </div>
            </div>
            

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default TeilnahmBedienungenPage;
